import React from "react";

import DefaultLayout from "../../layouts/default";

const Newsletter = () => {
    React.useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://app.mailjet.com/pas-nc-embedded-v1.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return(
  <DefaultLayout title="Newsletter">
    <div className="orange portrait">
      <header className="navWrapper">
        <nav className="mainNav singleNavBar">
          <div className="mainNavBackgroundHelper"></div>
          <div className="logo">
            <a href="/">
              <img
                src="/assets/images/logos/wigasoft-logo.svg"
                alt="WigaSoft Logo"
                width="175"
              />
            </a>
          </div>
          <div className="mainNav-opener"></div>
          <ul className="mainLinks">
            <li>
              <a href="/news/">News</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/heime/">Heime</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/spitaeler/">Spitäler</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/rehabilitation/">
                Rehabilitation
              </a>
            </li>
            <li>
              <a href="/portrait/">Über uns</a>
            </li>
            <li className="is-active">
              <a href="/support/">Support</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/wigasoft-ag">
                <div className="linkedInIcon" />
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <div className="menuBackground">
        <section>
          <div className="cContainer noSidesPadding is-background-color-orange is-color-white text-centered wsPaddingResp-bottom">
            <div className="wrapper minSidesPadding">
              <h1 className="largeStaticPadding-top mediumStaticPadding-bottom">
                Newsletter
              </h1>
            </div>
          </div>
        </section>
      </div>

      <section className="newsletterAnmeldung whiteVersion">
        <div className="wrapper nlPadding-top">
                  <iframe data-w-type="embedded" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://04h50.mjt.lu/wgt/04h50/x1to/form?c=c7df9f8c" width="100%" style={{height:0}} />

        </div>
      </section>

      <footer className="mainFooter">
        <div className="wrapper">
          <div className="breadcrumbs has-padding-small-top smallStaticPadding-bottom">
            <ul>
              <li>
                <a href="/">Startseite</a>
              </li>
              <li>
                <a href="/support/">Support</a>
              </li>
            </ul>
          </div>
          <div className="footerNotAnotherFlexboxButAGrid linklist">
            <div className="flex-item1">
              <ul>
                <li>
                  <a href="/dokumentationsloesungen/heime/">
                    <strong>Heime</strong>
                  </a>
                </li>
                <li>
                  <a href="/dokumentationsloesungen/spitaeler/">
                    <strong>Spitäler</strong>
                  </a>
                </li>
                <li>
                  <a href="/dokumentationsloesungen/rehabilitation/">
                    <strong>Rehabilitation</strong>
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-item2">
              <ul>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <span className="is-visible-desktop">WiCare|Doc</span>
                    <span className="is-visible-tablet-until-PC">WCD</span>-L
                    BESA
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                    <span className="is-visible-desktop">WiCare|Doc</span>
                    <span className="is-visible-tablet-until-PC">WCD</span>-L
                    RAI-NH
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-doc-b/">
                    <span className="is-visible-desktop">WiCare|Doc</span>
                    <span className="is-visible-tablet-until-PC">WCD</span>-B
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-doc-h/">
                    <span className="is-visible-desktop">WiCare|Doc</span>
                    <span className="is-visible-tablet-until-PC">WCD</span>-H
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-doc-r/">
                    <span className="is-visible-desktop">WiCare|Doc</span>
                    <span className="is-visible-tablet-until-PC">WCD</span>-R
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-item3">
              <ul>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-tacs/">
                    WiCare|now tacs<sup>&#174;</sup>
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-lep/">
                    WiCare|now LEP<sup>&#174;</sup>
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-le/">
                    WiCare|now LE
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-item4">
              <ul>
                <li>
                  <a href="/portrait/">
                    <strong>Über uns</strong>
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/portrait/lehrlinge/">Lernende</a>
                </li>
                <li className="partiallyHidden">
                  <a href="/portrait/partner/">Partner</a>
                </li>
                <li className="partiallyHidden">
                  <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
                </li>
                <li>
                  <a href="/portrait/kontakt/">Kontakt und Anfahrt</a>
                </li>
              </ul>
            </div>
            <div className="flex-item5">
              <ul>
                <li>
                  <a href="/support/">
                    <strong>Support</strong>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row copyright">
          <div className="wrapper">
            <div className="column">
              © 2025 WigaSoft. Alle Rechte vorbehalten.&nbsp;
            </div>
            <div className="column has-padding-left">
              <ul>
                <li>
                  <a href="/datenschutz/">Datenschutzerklärung</a>
                </li>
              <li>
                <a href="/impressum/">Impressum</a>
              </li>
              </ul>
            </div>
            <div className="column align-right">
              <a href="/portrait/kontakt/" className="anfahrt">
                <img
                  id="location-pointer"
                  src="/assets/images/icons/icon-anfahrt.svg"
                />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </DefaultLayout>
)};

export default Newsletter;
